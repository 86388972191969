import { BaseModel } from "./base.model";
import { CustomerInfoModel } from "./customer-info-model";

export class UnifiedCustomerModel extends BaseModel {
    constructor() {
        super();
        this.name = '';
        this.pomId = '';
        this.ucdCode = '';
        this.vendorId = '';
        // this.vcCustomerId = '';
        this.email = '';
        this.phoneNumber = '';
        this.companyRegNo = '';
        this.taxId = '';
        this.postalCode = '';
        this.salesRepId = '';
        this.status = '';
        this.approvedBy = '';
        this.note = '';
        this.referenceCodes = null; // [RefIdModel]
        this.verification = '';
        this.details = new CustomerInfoModel();
    }

    mappingData() {
        if (this.details) {
            this.name = this.details.representativeName;
            this.vendorId = this.details.vendorId;
            this.email = this.details.email;
            this.phoneNumber = this.details.phoneNumber;
            this.companyRegNo = this.details.profileInfo ? this.details.profileInfo.businessLicenseNo : '';
            this.taxId = this.details.billingInfo ? this.details.billingInfo.taxCode : '';
        }
    }
}

export class RefIdModel {
    constructor(refId, vendorId) {
        this.refId = refId;
        this.vendorId = vendorId;
    }

    static validate(obj, error) {
        let result = true;

        if (obj.refId === '') {
            error.refId = 'Reference ID is required';
            result = false;
        }
        if (obj.vendorId == null || obj.vendorId === '') {
            error.vendorId = 'Vendor is required';
            result = false;
        }

        return result;
    }
}