import { BillingInfoModel, ProfileInfoModel, ShippingInfoModel } from "./profile-model";

export const FormDataRequest = {
    BUSINESS_LICENSE_FILE_KEY: 'businessLicenseFile',
    PHARMA_BUSINESS_LICENSE_FILE_KEY: 'pharmaBusinessLicenseFile',
    GDP_GPP_GSP_FILE_KEY: 'gdpGppGspFile',
    TREATMENT_LICENSE_FILE_KEY: 'treatmentLicenseFile',
    DATA_KEY: 'data',
    // Support import customers from file
    FILE_KEY: 'file'
};

export class CustomerInfoModel {
    constructor(vendorId) {
        this.vendorId = vendorId;
        this.customerCode = '';
        this.salesRepId = '';
        this.representativeName = '';
        this.email = '';
        this.phoneNumber = '';
        this.userGroup = '';    // is Forensic group
        this.regionGroup = '';
        this.userGroupsVP = '';    // user groups (will be used in VietPOM)
        this.verification = '';
        this.profileInfo = new ProfileInfoModel();
        this.billingInfo = new BillingInfoModel();
        this.shippingInfo = new ShippingInfoModel();
        this.keyFile = null;
    }

    static validate(obj, error, isAdmin = true, ignoreRegionGroup = false) {
        let result = true;

        // Require customer code
        if (!isAdmin && (obj.customerCode == null || obj.customerCode === '')) {
            error.customerCode = 'Customer Code is required';
            result = false;
        }

        if (obj.userGroup == null || obj.userGroup === '') {
            error.userGroup = 'Forensic Group is required';
            result = false;
        }

        if (obj.representativeName == null || obj.representativeName === '') {
            error.representativeName = 'Representative Name is required';
            result = false;
        }

        if (obj.phoneNumber == null || obj.phoneNumber === '') {
            error.phoneNumber = 'Phone Number is required';
            result = false;
        }

        if (!ignoreRegionGroup && (obj.regionGroup == null || obj.regionGroup === '')) {
            error.regionGroup = 'Region Group is required';
            result = false;
        }

        return result;
    }
};
